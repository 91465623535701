import { HeaderData } from "../../../components/Header";
import { User } from "../../../types/types";
import { usePayersForUserQuery } from "./payers";
import {
  useEmployerForUserQuery,
  useOnboardingTasksQuery,
  useWalletForUserQuery,
} from "./users";

export const useHeaderQuery = (
  loggedInUser: User | null,
): HeaderData & {
  isLoading: boolean;
  headerIsLoading: boolean;
  onboardingTasksIsLoading: boolean;
  payersForUserIsLoading: boolean;
} => {
  const { data: loggedInEmployer, isLoading: loggedInEmployerIsLoading } =
    useEmployerForUserQuery(loggedInUser?.id, {
      skip: !loggedInUser?.id,
    });

  const { data: onboardingTasks, isLoading: onboardingTasksIsLoading } =
    useOnboardingTasksQuery(
      { userId: loggedInUser?.id ?? "" },
      {
        skip: !loggedInUser?.id,
      },
    );

  const { data: payersForUser, isLoading: payersForUserIsLoading } =
    usePayersForUserQuery(loggedInUser?.id, {
      skip: !loggedInUser?.id,
    });

  const { data: wallet, isLoading: walletIsLoading } = useWalletForUserQuery(
    loggedInUser?.id,
    {
      skip: !loggedInUser?.id,
    },
  );

  return {
    loggedInEmployer: loggedInEmployer ?? null,
    onboardingTasks: onboardingTasks ?? [],
    payersForUser: payersForUser ?? [],
    isLoading:
      loggedInEmployerIsLoading ||
      onboardingTasksIsLoading ||
      payersForUserIsLoading ||
      walletIsLoading,
    headerIsLoading: loggedInEmployerIsLoading || walletIsLoading,
    onboardingTasksIsLoading,
    payersForUserIsLoading,
    wallet,
  };
};
