import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Payer, User } from "../../types/types";

type UserDetailsState = {
  user: User | null;
  employer: Payer | null;
  userHasAddress: boolean;
  userHasName: boolean;
};

const initialState: UserDetailsState = {
  user: null,
  employer: null,
  userHasAddress: false,
  userHasName: false,
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUser(state: UserDetailsState, action: PayloadAction<User>) {
      state.user = action.payload;
      state.userHasAddress = false;
      state.userHasName = false;
      if (
        state.user.address_line1 &&
        state.user.postcode &&
        state.user.country &&
        state.user.city
      ) {
        state.userHasAddress = true;
      }
      if (state.user.first_name && state.user.last_name) {
        state.userHasName = true;
      }
    },
    setEmployer(state: UserDetailsState, action: PayloadAction<Payer>) {
      state.employer = action.payload;
    },
    emptyEmployer(state: UserDetailsState) {
      state.employer = null;
    },
  },
});

export const selectLoggedInUser = (state: UserDetailsState) => state.user;

export default userDetailsSlice;
