import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Payer, PayerForUser, Provider } from "../../types/types";

type EmployerState = {
  loggedInUsersEmployer: PayerForUser | null;

  currentEmployer: Payer | null;

  selectedProviders: Provider[];

  allEmployers: Payer[];
};

const initialState: EmployerState = {
  loggedInUsersEmployer: null,
  selectedProviders: new Array<Provider>(),
  allEmployers: [],
  currentEmployer: null,
};

const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setLoggedInUsersEmployer(
      state: EmployerState,
      action: PayloadAction<PayerForUser>,
    ) {
      state.loggedInUsersEmployer = action.payload;
    },
    setSelectedProviders(
      state: EmployerState,
      action: PayloadAction<Provider[]>,
    ) {
      state.selectedProviders = action.payload;
    },
    setAllEmployers(state: EmployerState, action: PayloadAction<Payer[]>) {
      state.allEmployers = action.payload;
    },
    setCurrentEmployer(state: EmployerState, action: PayloadAction<Payer>) {
      state.currentEmployer = action.payload;
    },
  },
});

export default employerSlice;
