import { ArrowForward } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/hooks";
import { useUpdateOnboardingTasksMutation } from "../../store/slices/api/users";
import { OnboardingTaskEnum } from "../../types/types";

export const getTitleAndDescriptionForOnboardingTask = (
  task: OnboardingTaskEnum,
  t: ReturnType<typeof useTranslation>["t"],
): {
  title: string;
  description: string;
} => {
  return {
    title: t(`menu-actions.${task}.title`),
    description: t(`menu-actions.${task}.description`),
  };
};

const openTask = (
  task: OnboardingTaskEnum,
  navigate: ReturnType<typeof useNavigate>,
  closeMenu: () => void,
  employerId?: string,
) => {
  switch (task) {
    case OnboardingTaskEnum.COMPLETE_PROFILE:
      navigate("/profile/personal");
      break;
    case OnboardingTaskEnum.CONNECT_PAYER:
      navigate("/profile/organisations", {});
      break;
    case OnboardingTaskEnum.CREATE_EMPLOYER:
      navigate("/createEmployerWelcome");
      break;
    case OnboardingTaskEnum.ONBOARD_USERS:
      navigate(`/employerPortal/${employerId}/manage-users`);
      break;
    case OnboardingTaskEnum.SETUP_PERSONALISATION:
      navigate("/you?personalise=true");
      break;
    case OnboardingTaskEnum.SUBSCRIBE_NEWSLETTER:
      navigate("/profile/email-notifications");
      break;
  }

  closeMenu();
};

const clearNotificationIcon = (task: OnboardingTaskEnum) => {
  const nonDismissibleDismisableNotificationIcons = [
    OnboardingTaskEnum.CREATE_EMPLOYER,
    OnboardingTaskEnum.ONBOARD_USERS,
  ];
  return !nonDismissibleDismisableNotificationIcons.includes(task);
};

export const MenuActions = ({
  task,
  employerId,
  notificationBubble,
  remindMe,
  closeMenu,
}: {
  notificationBubble: boolean;
  remindMe: boolean;
  task: OnboardingTaskEnum;
  employerId?: string;

  closeMenu: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const [updateOnboardingTasks] = useUpdateOnboardingTasksMutation();

  const { title, description } = getTitleAndDescriptionForOnboardingTask(
    task,
    t,
  );

  return (
    <button
      className="flex flex-row gap-2 pr-6 px-2 py-3 w-full items-center border-b-[1px] border-blue-1 hover:bg-background motion-reduce:transition-none group focus:outline-none focus:ring focus:ring-offset-2 focus:ring-[#105a9a]"
      onClick={(e) => {
        openTask(task, navigate, closeMenu, employerId);
      }}
      onMouseOver={async () => {
        if (notificationBubble && clearNotificationIcon(task))
          await updateOnboardingTasks({
            onboardingTask: {
              onboardingTask: task,
              showNotificationIndicator: false,
              taskComplete: false,
              remindMe: remindMe,
            },
            userId: loggedInUser?.id ?? "",
          });
      }}
    >
      <div className="flex flex-col flex-1">
        <div className="flex flex-row gap-2">
          <div className="w-[9px]">
            {
              <svg
                width="0.5625rem"
                height="1.8125rem"
                viewBox="0 0 0.5625rem 1.25rem"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={!notificationBubble}
              >
                {notificationBubble && <title>Unseen notification</title>}
                <circle
                  cx="0.28125rem"
                  cy="0.8125rem"
                  r={notificationBubble ? "0.25rem" : "0"}
                  className="transition-all"
                  fill="#E35349"
                />
              </svg>
            }
          </div>
          <div className="flex flex-col">
            <Typography
              variant="titleXS"
              sx={{
                textAlign: "start",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="bodyS"
              sx={{
                textAlign: "start",
              }}
            >
              {description}
            </Typography>
          </div>
        </div>
      </div>
      <div className="group-hover:translate-x-2 transition-transform">
        <ArrowForward
          sx={{
            color: "#105A9A",
            width: "20px",
            height: "20px",
          }}
        />
      </div>
    </button>
  );
};
