import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OnboardingRemindersState = {
  onboardingReminderSeen: boolean;
};

const initialState: OnboardingRemindersState = {
  onboardingReminderSeen: false,
};

const insurerSlice = createSlice({
  name: "insurer",
  initialState,
  reducers: {
    setOnboardingReminderSeen(
      state: OnboardingRemindersState,
      action: PayloadAction<boolean>,
    ) {
      state.onboardingReminderSeen = action.payload;
    },
  },
});

export default insurerSlice;
