import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const StepIndicator = ({
  currentStep,
  totalSteps,
  stepText,
}: {
  currentStep: number;
  totalSteps: number;
  stepText?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className="py-1 px-4 rounded-[56px] bg-blue-1">
      <Typography variant="titleXS" sx={{ color: "#373737" }}>
        {stepText ? (
          stepText
        ) : (
          <>
            {currentStep <= totalSteps &&
              t("general.step-indicator.step", {
                currentStep: currentStep,
                totalSteps: totalSteps,
              })}
            {currentStep > totalSteps && t("general.step-indicator.all-done")}
          </>
        )}
      </Typography>
    </div>
  );
};
