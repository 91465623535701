import { Typography } from "@mui/material";

export const Step = ({
  content,
  svg,
}: {
  content: string;
  svg: React.ReactNode;
}) => {
  return (
    <div
      className={`w-full relative flex-1 flex flex-row lg:flex-col justify-between bg-white rounded-lg md:w-[37.5rem] lg:w-[17.7rem] xl:w-[20rem] min-h-[7.5rem] lg:min-h-[15.1rem] overflow-clip`}
    >
      <div className="px-4 py-4">
        <Typography
          variant="titleM"
          sx={{
            color: "#212529",
          }}
        >
          {content}
        </Typography>
      </div>
      <div className="self-end">{svg}</div>
    </div>
  );
};
