type CalloutType = "error" | "generic" | "success";

export const Callout = ({
  children,
  type = "generic",
}: {
  children: React.ReactNode;
  type?: string;
}) => {
  let color = "";
  switch (type) {
    case "error":
      color = "bg-feedback-error-light text-feedback-error-dark";
      break;
    case "success":
      color = "bg-feedback-positive-light text-feedback-positive-dark";
      break;
    case "generic":
      color = "bg-background text-blue-4";
      break;
  }

  return (
    <div
      className={`flex flex-row gap-2 items-center rounded-lg px-3 py-2 ${color}`}
    >
      {children}
    </div>
  );
};
