export const HealthKeyContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="min-h-screen flex flex-col overflow-x-clip bg-background bg-gradient-to-b from-white from-[80px] to-[280px]">
      {children}
    </div>
  );
};
