import { Payer, PayerForUser, RemoveUserFromPayer } from "../../../types/types";
import { healthkeyAPI } from "../healthkeyAPI";
import { cacheTime, Entity } from "./definitions";

const payersAPI = healthkeyAPI.injectEndpoints({
  endpoints: (builder) => ({
    addUserToPayer: builder.mutation<
      PayerForUser,
      {
        employerId: string;
        userId: string;
        payerType: string;
      }
    >({
      query: ({ employerId, userId, payerType }) => ({
        method: "POST",
        url: `/payers/${employerId}/users/${userId}`,
        body: {
          type: payerType,
          payerUniqueIdentifier: null,
          stackOneId: null,
        },
      }),
      invalidatesTags: [
        Entity.OnboardingTask,
        Entity.PayersForUser,
        Entity.EmployerForUser,
      ],
    }),
    publishedPayers: builder.query<Payer[], void>({
      keepUnusedDataFor: cacheTime[Entity.Employer],
      query: () => `/payers?published=true`,
    }),
    payersForUser: builder.query<PayerForUser[], string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.PayersForUser],
      query: (userId) => `/users/${userId}/payers`,
      providesTags: [Entity.PayersForUser],
    }),
    payerForUser: builder.query<
      PayerForUser,
      { userId: string | undefined; payerId: string | undefined }
    >({
      keepUnusedDataFor: cacheTime[Entity.PayersForUser],
      query: ({ userId, payerId }) => ({
        url: `/users/${userId}/payers/${payerId}`,
      }),
      providesTags: [Entity.PayersForUser],
    }),
    updatePayer: builder.mutation<Payer, Partial<Payer>>({
      query: (payer) => ({
        method: "PUT",
        url: `/payers/${payer.id}`,
        body: payer,
      }),
      invalidatesTags: [Entity.EmployerForUser, Entity.Employer],
    }),
    removeUserFromPayer: builder.mutation<void, RemoveUserFromPayer>({
      query: (removeUser: RemoveUserFromPayer) => ({
        method: "DELETE",
        url: `/payers/${removeUser.payerId}/users/${removeUser.userId}`,
      }),
      invalidatesTags: [
        Entity.EmployerForUser,
        Entity.Employer,
        Entity.EmployerInfo,
        Entity.OnboardingTask,
        Entity.PayersForUser,
      ],
    }),
    readAdmins: builder.query<string[], string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.AdminsForPayer],
      query: (payerId) => `/payers/${payerId}/admins`,
      providesTags: [Entity.AdminsForPayer],
    }),
    addAdmin: builder.mutation<void, { payerId: string; adminEmail: string }>({
      query: ({ payerId, adminEmail }) => ({
        method: "POST",
        url: `/payers/${payerId}/admins/${adminEmail}`,
      }),
      invalidatesTags: [Entity.AdminsForPayer],
    }),
    removeAdmin: builder.mutation<
      void,
      { payerId: string; adminEmail: string }
    >({
      query: ({ payerId, adminEmail }) => ({
        method: "DELETE",
        url: `/payers/${payerId}/admins/${adminEmail}`,
      }),
      invalidatesTags: [Entity.AdminsForPayer],
    }),
  }),
});

export const {
  useAddUserToPayerMutation,
  usePublishedPayersQuery,
  usePayersForUserQuery,
  usePayerForUserQuery,
  useRemoveUserFromPayerMutation,
  useUpdatePayerMutation,
  useReadAdminsQuery,
  useAddAdminMutation,
  useRemoveAdminMutation,
} = payersAPI;
