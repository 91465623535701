import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";

import LogoBanner from "../new/LogoCenteredInsideComponent";

export const OnboardingContentBubble = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isBiggerThanSmBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const [observing, setObserving] = useState(null as null | HTMLDivElement);
  const [height, setHeight] = useState(isBiggerThanSmBreakpoint ? 31 : 55);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        setHeight(boundingRect.height);
      }
    });

    if (ref?.current && !observing) {
      resizeObserver.observe(ref?.current);
      setObserving(ref?.current);
    }

    return () => {
      if (observing) {
        resizeObserver.unobserve(observing);
        setObserving(null);
      }
    };
  }, [observing, ref]);

  return (
    <>
      <div
        className="fixed inset-x-0 top-0 bg-feedback-positive-light flex flex-col items-center justify-center px-3 py-1 z-10"
        ref={ref}
      >
        <Typography variant="bodyS" sx={{ color: "#056753" }}>
          <Trans
            i18nKey="employer-portal.onboarding.contact-us"
            components={{
              contact: (
                // Anchor is given content by the Trans component
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="underline"
                  style={{ color: "#056753", fontWeight: "700" }}
                  href={process.env.REACT_APP_EMPLOYER_ONBOARDING_CONTACT_US}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </div>
      <div
        style={{
          marginTop: `${height}px`,
        }}
        className="flex flex-col items-center rounded-lg sm:mt-0 w-full flex-1 lg:mx-auto lg:max-w-[944px] xl:max-w-[1380px] 3xl:max-w-[1500px] xl:mx-auto"
      >
        <LogoBanner />
        <div className="min-h-[calc(min(680px,80vh))] w-full">{children}</div>
      </div>
    </>
  );
};
