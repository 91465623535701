import {
  ExpandMoreRounded,
  SearchRounded,
  SentimentSatisfiedAltRounded,
} from "@mui/icons-material";
import { Box, Drawer, styled, Tab, Tabs, TabsProps } from "@mui/material";
import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MenuSVG } from "../../images/menu-book-icon.svg";
import { useAppSelector } from "../../store/hooks";
import { useFilteredContentQuery } from "../../store/slices/healthkeyAPI";
import { NavigationTabsHomeProps } from "../../types/props";
import HamburgerDrawerSubItem from "../design-system/HamburgerDrawerSubItem";

type StyledTabsProps = {
  children?: React.ReactNode;
  value: string | boolean;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
} & TabsProps;

export const HeaderTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  paddingBottom: "6px",
  paddingTop: "16px",
  fontSize: "1rem",
  minHeight: "0",
  flexGrow: "1",
  display: "flex",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#D83E1D",
    borderRadius: "10px",
  },
  button: {
    gap: "4px",
  },
});

const HeaderTab = ({
  label,
  tabIndex,
  value,
  onClick,
  currentValue,
  icon,
}: {
  label: string;
  tabIndex: number;
  value: string;
  onClick: () => void;
  currentValue: string;
  icon?: ReactElement<any, string | JSXElementConstructor<any>>;
}) => {
  const selected = value === currentValue;
  return (
    <Tab
      label={label}
      tabIndex={tabIndex}
      value={value}
      component="button"
      icon={icon}
      onClick={onClick}
      sx={{
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "150%",
        letterSpacing: "-0.32px",
        color: selected ? "#D83E1D" : "#373737",
        opacity: 1,
        "&.MuiButtonBase-root": {
          paddingTop: "0",
          paddingBottom: "8px",
          paddingX: { xs: "8px", xl: "24px" },
          minHeight: "34px",
        },
      }}
    />
  );
};

type MenuDrawerProps = {
  children: React.ReactNode;

  open: boolean;

  setOpen: (b: boolean) => void;
};

const MenuDrawer = ({ children, open, setOpen }: MenuDrawerProps) => (
  <Drawer
    open={open}
    anchor="top"
    onClose={() => setOpen(false)}
    transitionDuration={500}
    sx={{
      "& .MuiBackdrop-root": {
        background: "transparent",
        opacity: 0,
      },
    }}
  >
    <Box
      sx={{
        padding: {
          lg: "24px 96px 40px",
          xl: "24px 248px 40px",
          xxl: "24px 296px 40px",
          xxxl: "24px 360px 40px",
        },
        display: "flex",
        flexDirection: "row",
      }}
    >
      {children}
    </Box>
  </Drawer>
);

const NavigationTabsHome = (props: NavigationTabsHomeProps) => {
  const navigate = useNavigate();

  const [value, setValue] = useState(props.selectedTab);

  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const { data: listOfContent } = useFilteredContentQuery({
    categories: [],
  });

  const { t } = useTranslation();

  const [platformOpen, setPlatformOpen] = useState(false);

  const [helpOpen, setHelpOpen] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const closeSubMenus = () => {
    setHelpOpen(false);
    setPlatformOpen(false);
  };

  return (
    <div role="navigation">
      <HeaderTabs value={value ? value : false} onChange={handleChange}>
        {loggedInUser && !props.website ? (
          <HeaderTab
            label={t("navigation.you")}
            tabIndex={1}
            value={"0"}
            currentValue={value ?? ""}
            icon={
              <SentimentSatisfiedAltRounded
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => navigate("/you")}
          />
        ) : (
          <HeaderTab
            label={t("navigation.platform.title")}
            tabIndex={1}
            value={"0"}
            currentValue={value ?? ""}
            icon={
              <ExpandMoreRounded
                sx={{
                  "&.MuiTab-iconWrapper": {
                    width: "20px",
                    height: "20px",
                  },
                }}
              />
            }
            onClick={() => {
              setPlatformOpen(!platformOpen);
              setHelpOpen(false);
            }}
          />
        )}
        {loggedInUser && !props.website ? (
          <HeaderTab
            label={t("navigation.browse-care")}
            tabIndex={1}
            value={"1"}
            currentValue={value ?? ""}
            icon={
              <SearchRounded
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => navigate("/findCare")}
          />
        ) : (
          <HeaderTab
            label={t("navigation.browse-care")}
            tabIndex={1}
            value={"1"}
            currentValue={value ?? ""}
            onClick={() => {
              closeSubMenus();
              navigate("/findCare");
            }}
          />
        )}
        {loggedInUser &&
          !props.website &&
          listOfContent &&
          listOfContent.length > 0 && (
            <HeaderTab
              label={t("navigation.content")}
              tabIndex={1}
              value={"2"}
              currentValue={value ?? ""}
              icon={<MenuSVG className="ml-0.5 xl:ml-1 w-[20px] h-[20px]" />}
              onClick={() => navigate("/content")}
            />
          )}

        {(!loggedInUser || props.website) && (
          <HeaderTab
            label={t("navigation.help-resources.title")}
            tabIndex={1}
            value={"2"}
            currentValue={value ?? ""}
            icon={
              <ExpandMoreRounded
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => {
              setPlatformOpen(false);
              setHelpOpen(!helpOpen);
            }}
          />
        )}
        {(!loggedInUser || props.website) && (
          <HeaderTab
            label={t("navigation.pricing")}
            tabIndex={1}
            value={"3"}
            currentValue={value ?? ""}
            onClick={() => {
              closeSubMenus();
              navigate("/pricing");
            }}
          />
        )}
        {(!loggedInUser || props.website) && (
          <HeaderTab
            label={t("navigation.about")}
            tabIndex={1}
            value={"4"}
            currentValue={value ?? ""}
            onClick={() => {
              closeSubMenus();
              navigate("/about");
            }}
          />
        )}
      </HeaderTabs>
      <MenuDrawer open={helpOpen} setOpen={setHelpOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "40px",
          }}
        >
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.faqs")}
            subTitle={t("navigation.help-resources.faqs-description")}
            onClick={() => navigate("/help/FAQs")}
          />
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.contact")}
            subTitle={t("navigation.help-resources.contact-description")}
            onClick={() => navigate("/help/contact")}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.api-docs")}
            subTitle={t("navigation.help-resources.api-docs-description")}
            onClick={() =>
              window.open(process.env.REACT_APP_API_DOCS_URL, "_blank")
            }
          />
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.careers")}
            subTitle={t("navigation.help-resources.careers-description")}
            onClick={() =>
              window.open(process.env.REACT_APP_CAREERS_PAGE_URL, "_blank")
            }
          />
        </Box>
      </MenuDrawer>
      <MenuDrawer open={platformOpen} setOpen={setPlatformOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "40px",
          }}
        >
          <HamburgerDrawerSubItem
            title={t("navigation.platform.employers")}
            subTitle={t("navigation.platform.employers-description")}
            onClick={() => navigate("/home/employers")}
          />
          <HamburgerDrawerSubItem
            title={t("navigation.platform.users")}
            subTitle={t("navigation.platform.users-description")}
            onClick={() => navigate("/home/individuals")}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <HamburgerDrawerSubItem
            title={t("navigation.platform.providers")}
            subTitle={t("navigation.platform.providers-description")}
            onClick={() => navigate("/home/providers")}
          />
        </Box>
      </MenuDrawer>
    </div>
  );
};

export default NavigationTabsHome;
