import "react-toastify/dist/ReactToastify.css";
import "./style/toastify.css";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MotionConfig } from "framer-motion";
import React, { lazy, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { MatomoHistoryListener } from "./components/MatomoHistoryListener";
import { PageLoader } from "./components/PageLoader";
import ErrorPage from "./pages/ErrorPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import store, { persistor } from "./store/store";
import { healthKeyV2Theme } from "./themes/theme";

export const router = createBrowserRouter([{ path: "*", Component: Root }]);

const You = lazy(() => import("./pages/You"));
const FindCare = lazy(() => import("./pages/FindCare"));
const ViewPackage = lazy(() => import("./pages/ViewPackage"));
const SearchResults = lazy(() => import("./pages/SearchResults"));
const HealthCategory = lazy(() => import("./pages/HealthCategoryPage"));
const Profile = lazy(() => import("./pages/Profile"));
const Login2 = lazy(() => import("./pages/Login2"));
const Register2 = lazy(() => import("./pages/sign-up/Register2"));
const InvitedByParentCompany = lazy(
  () => import("./pages/sign-up/InvitedByParentCompany"),
);
const Waitlist = lazy(() => import("./pages/sign-up/Waitlist"));
const CreateEmployerWelcome = lazy(
  () => import("./pages/sign-up/CreateEmployerWelcome"),
);
const CreateEmployerLater = lazy(
  () => import("./pages/sign-up/CreateEmployerLater"),
);

const EmployerOnboardingFinished = lazy(
  () => import("./pages/sign-up/EmployerOnboardingFinished"),
);

const CreateEmployer = lazy(() => import("./pages/sign-up/CreateEmployer"));
const OnboardEmployer = lazy(
  () => import("./pages/employer-portal/OnboardEmployer"),
);
const Onboard = lazy(() => import("./pages/sign-up/Onboard"));
const ManageEmailPreferences = lazy(
  () => import("./pages/ManageEmailPreferences"),
);
const SiteMap = lazy(() => import("./pages/SiteMap"));
const Unsubscribe = lazy(() => import("./pages/Unsubscribe"));
const ExpandedProviderPage2 = lazy(
  () => import("./pages/ExpandedProviderPage2"),
);
const ExpandedServicePage = lazy(() => import("./pages/ExpandedServicePage"));
const AccountDeleted = lazy(() => import("./pages/AccountDeleted"));
const HealthPartnerDashboard = lazy(
  () => import("./pages/health-partner/HealthPartnerDashboard"),
);
const HealthPartnerProfile = lazy(
  () => import("./pages/health-partner/HealthPartnerProfile"),
);

const HealthPartnerEmployers = lazy(
  () => import("./pages/health-partner/HealthPartnerEmployers"),
);
const EmployerInsightsAndReporting = lazy(
  () => import("./pages/employer-portal/EmployerInsightsAndReporting"),
);
const EmployerProviders = lazy(
  () => import("./pages/employer-portal/EmployerProviders"),
);

const OktaSSO = lazy(() => import("./pages/OktaSSO"));

const EmployerManageUsers = lazy(
  () => import("./pages/employer-portal/manage-users/EmployerManageUsers"),
);

const EmployerHRISIntegration = lazy(
  () => import("./pages/employer-portal/manage-users/EmployerHRIS"),
);

const EmployerAddAndRemoveUsersCsvImport = lazy(
  () =>
    import(
      "./pages/employer-portal/manage-users/EmployerAddAndRemoveUsersCsvImport"
    ),
);
const EmployerAddAndRemoveUsersInviteLink = lazy(
  () =>
    import(
      "./pages/employer-portal/manage-users/EmployerAddAndRemoveUsersInviteLink"
    ),
);
const EmployerSettingsAppearance = lazy(
  () => import("./pages/employer-portal/settings/EmployerSettingsAppearance"),
);
const EmployerSettingsBudget = lazy(
  () => import("./pages/employer-portal/settings/EmployerSettingsBudget"),
);

const EmployerSettingsWelcomeMessage = lazy(
  () =>
    import("./pages/employer-portal/settings/EmployerSettingsWelcomeMessage"),
);

const HealthPartnerServices = lazy(
  () => import("./pages/health-partner/HealthPartnerServices"),
);

const HealthPartnerContentPage = lazy(
  () => import("./pages/health-partner/HealthPartnerContent"),
);
const FinishRegistration2 = lazy(
  () => import("./pages/sign-up/FinishRegistration2"),
);
const ForgottenPasswordEmail2 = lazy(
  () => import("./pages/forgotten-password/ForgottenPasswordEmail2"),
);
const ForgottenPasswordEmailSent2 = lazy(
  () => import("./pages/forgotten-password/ForgottenPasswordEmailSent2"),
);
const ForgottenPasswordChangePassword2 = lazy(
  () => import("./pages/forgotten-password/ForgottenPasswordChangePassword2"),
);

const AcceptInvite = lazy(() => import("./pages/sign-up/AcceptInvite"));
const SessionExpired2 = lazy(() => import("./pages/SessionExpired2"));
const Wishlist = lazy(() => import("./pages/Wishlist"));

const Home = lazy(() => import("./pages/website/Home"));
const Employers = lazy(() => import("./pages/website/Employers"));
const Providers = lazy(() => import("./pages/website/Providers"));
const Users = lazy(() => import("./pages/website/Individuals"));
const About = lazy(() => import("./pages/website/About"));
const FAQs = lazy(() => import("./pages/website/FAQs"));
const Pricing = lazy(() => import("./pages/website/Pricing"));
const Contact = lazy(() => import("./pages/website/Contact"));
const ContactEmployers = lazy(() => import("./pages/website/ContactEmployers"));
const DesignPreview = lazy(() => import("./pages/DesignPreview"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const OAuthConsentPage = lazy(() => import("./pages/OAuthConsentPage"));
const ServicesForYouPage = lazy(() => import("./pages/ServicesForYouPage"));
const ContentMainPage = lazy(
  () => import("./pages/content-hub/ContentMainPage"),
);
const HealthCategoryContentPage = lazy(
  () => import("./pages/HealthCategoryContentPage"),
);
const ContentForYouPage = lazy(() => import("./pages/ContentForYouPage"));
const ArticlePage = lazy(() => import("./pages/content-hub/ArticlePage"));
const VideoPage = lazy(() => import("./pages/content-hub/VideoPage"));

const AllContent = lazy(() => import("./pages/content-hub/AllContent"));

const AffordableServices = lazy(() => import("./pages/AffordableServices"));
const TestingActivateEmail = lazy(() => import("./pages/TestingActivateEmail"));

const HealthKeyAdminHome = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminHome"),
);
const HealthKeyAdminEmployers = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminEmployers"),
);
const HealthKeyAdminProviders = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminProviders"),
);
const HealthKeyAdminServices = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminServices"),
);
const HealthKeyAdminContent = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminContent"),
);
const HealthKeyAdminUsers = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminUsers"),
);
const HealthKeyAdminPurchases = lazy(
  () => import("./pages/healthkey-admin/HealthKeyAdminPurchases"),
);
export default function App() {
  return <RouterProvider router={router} />;
}

const HealthKeySuspense = ({ children }: { children: React.ReactNode }) => {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
};

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  console.error("error", error);
  return <ErrorPage />;
};

const employerPortal = (): React.ReactNode[] => {
  return [
    <Route
      key="/employerPortal/:payerId/dashboard"
      path="/employerPortal/:payerId/dashboard"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerInsightsAndReporting />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/providers"
      path="/employerPortal/:payerId/providers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerProviders />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users"
      path="/employerPortal/:payerId/manage-users"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerManageUsers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users/add-and-remove/csv-upload"
      path="/employerPortal/:payerId/manage-users/add-and-remove/csv-upload"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerAddAndRemoveUsersCsvImport />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/manage-users/add-and-remove/invite-link"
      path="/employerPortal/:payerId/manage-users/add-and-remove/invite-link"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerAddAndRemoveUsersInviteLink />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/employerPortal/:payerId/manage-users/add-and-remove/hr-integration"
      key="/employerPortal/:payerId/manage-users/add-and-remove/hr-integration"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerHRISIntegration />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/budget"
      path="/employerPortal/:payerId/settings/budget"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsBudget />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/appearance"
      path="/employerPortal/:payerId/settings/appearance"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsAppearance />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/employerPortal/:payerId/settings/welcome-message"
      path="/employerPortal/:payerId/settings/welcome-message"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <EmployerSettingsWelcomeMessage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
  ];
};

const providerPortal = (): React.ReactNode[] => {
  return [
    <Route
      key="/healthPartner/employers"
      path="/healthPartner/employers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerEmployers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/healthPartner/dashboard"
      path="/healthPartner/dashboard"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerDashboard />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/dashboard"
      key="/healthPartner/dashboard"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerDashboard />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/profile"
      key="/healthPartner/profile"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerProfile />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/services"
      key="/healthPartner/services"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerServices />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/healthPartner/content"
      key="/healthPartner/content"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthPartnerContentPage />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
  ];
};

const healthKeyAdminPortal = (): React.ReactNode[] => {
  return [
    <Route
      key="/adminPortal/home"
      path="/adminPortal/home"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminHome />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      key="/adminPortal/employers"
      path="/adminPortal/employers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminEmployers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/providers"
      key="/adminPortal/providers"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminProviders />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/services"
      key="/adminPortal/services"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminServices />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/content"
      key="/adminPortal/content"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminContent />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/users"
      key="/adminPortal/users"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminUsers />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
    <Route
      path="/adminPortal/purchases"
      key="/adminPortal/purchases"
      element={
        <ProtectedRoute>
          <HealthKeySuspense>
            <HealthKeyAdminPurchases />
          </HealthKeySuspense>
        </ProtectedRoute>
      }
    />,
  ];
};

export function Root() {
  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            fontFamily: `Karla, Roboto, "Helvetica Neue", Arial, sans-serif`,
          },
        }}
      />
      <ThemeProvider theme={healthKeyV2Theme}>
        <MotionConfig reducedMotion="user">
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
              <HelmetProvider>
                <ErrorBoundary
                  fallbackRender={Fallback}
                  onReset={(details) => {
                    // Reset the state of your app so the error doesn't happen again
                    console.warn(details);
                  }}
                >
                  <MatomoHistoryListener>
                    <Routes>
                      <Route
                        path="/login"
                        element={
                          <HealthKeySuspense>
                            <Login2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/register/:userEmail"
                        element={
                          <HealthKeySuspense>
                            <Register2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/invite/:parentEmployerId"
                        element={
                          <HealthKeySuspense>
                            <InvitedByParentCompany />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/waitlist"
                        element={
                          <HealthKeySuspense>
                            <Waitlist />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/createEmployerWelcome"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <CreateEmployerWelcome />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/createEmployer"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <CreateEmployer />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/createEmployerFinished"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <EmployerOnboardingFinished />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/createEmployerLater"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <CreateEmployerLater />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/onboardEmployer/:activeStep?"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <OnboardEmployer />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/onboard/:employerId"
                        element={
                          <HealthKeySuspense>
                            <Onboard />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/manageEmailPreferences"
                        element={
                          <HealthKeySuspense>
                            <ManageEmailPreferences />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/unsubscribe"
                        element={
                          <HealthKeySuspense>
                            <Unsubscribe />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/siteMap"
                        element={
                          <HealthKeySuspense>
                            <SiteMap />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/finishRegistration/:userId"
                        element={
                          <HealthKeySuspense>
                            <FinishRegistration2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/finishRegistration/:userId/:payerId"
                        element={
                          <HealthKeySuspense>
                            <FinishRegistration2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/finishRegistration"
                        element={
                          <HealthKeySuspense>
                            <FinishRegistration2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordEmail"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordEmail2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordEmail/:userEmail"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordEmail2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordEmailSent"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordEmailSent2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/forgottenPasswordChangePassword/:resetToken"
                        element={
                          <HealthKeySuspense>
                            <ForgottenPasswordChangePassword2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/acceptInvite/:userId/:resetToken/:payerId"
                        element={
                          <HealthKeySuspense>
                            <AcceptInvite />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/okta-sso"
                        element={
                          <HealthKeySuspense>
                            <OktaSSO />
                          </HealthKeySuspense>
                        }
                      />

                      <Route
                        path="/error"
                        element={
                          <HealthKeySuspense>
                            <ErrorPage />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/undefined"
                        element={
                          <HealthKeySuspense>
                            <ErrorPage />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/sessionExpired"
                        element={
                          <HealthKeySuspense>
                            <SessionExpired2 />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/accountDeleted"
                        element={
                          <HealthKeySuspense>
                            <AccountDeleted />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/"
                        element={
                          <HealthKeySuspense>
                            <Home />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/new"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <You />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/you"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <You />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Profile />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/wishlist"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Wishlist />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/profile/:section"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <Profile />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/affordableCare"
                        element={
                          <HealthKeySuspense>
                            <AffordableServices />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare"
                        element={
                          <HealthKeySuspense>
                            <FindCare />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare/results"
                        element={
                          <HealthKeySuspense>
                            <SearchResults />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare/healthCategory/:healthCategory"
                        element={
                          <HealthKeySuspense>
                            <HealthCategory />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/findCare/:page"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <FindCare />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/findCare/forYou"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ServicesForYouPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/package/:packageId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ViewPackage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ContentMainPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/healthCategory/:healthCategory"
                        element={
                          <HealthKeySuspense>
                            <HealthCategoryContentPage />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/content/forYou"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ContentForYouPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/:type"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <AllContent />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/:type/results"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <SearchResults />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/content/results"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <SearchResults />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/article/:articleId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ArticlePage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/video/:videoId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <VideoPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/new/paymentConfirmation/:amount"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <FindCare />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/new/paymentConfirmation/:amount/:transactionIdToComplete"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <FindCare />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/provider/:providerId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ExpandedProviderPage2 />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/service/:serviceId"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <ExpandedServicePage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      {employerPortal()}
                      {providerPortal()}
                      {healthKeyAdminPortal()}
                      <Route
                        path="/oauth"
                        element={
                          <ProtectedRoute>
                            <HealthKeySuspense>
                              <OAuthConsentPage />
                            </HealthKeySuspense>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/home"
                        element={
                          <HealthKeySuspense>
                            <Home />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/home/employers"
                        element={
                          <HealthKeySuspense>
                            <Employers />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/for-employers"
                        element={
                          <HealthKeySuspense>
                            <Employers />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/home/providers"
                        element={
                          <HealthKeySuspense>
                            <Providers />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/home/individuals"
                        element={
                          <HealthKeySuspense>
                            <Users />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/help/FAQs"
                        element={
                          <HealthKeySuspense>
                            <FAQs />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/help/contact"
                        element={
                          <HealthKeySuspense>
                            <Contact />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/help/contact-employers"
                        element={
                          <HealthKeySuspense>
                            <ContactEmployers />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/pricing"
                        element={
                          <HealthKeySuspense>
                            <Pricing />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/about"
                        element={
                          <HealthKeySuspense>
                            <About />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/design-preview"
                        element={
                          <HealthKeySuspense>
                            <DesignPreview />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/privacy-policy"
                        element={
                          <HealthKeySuspense>
                            <PrivacyPolicy />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/terms-of-use"
                        element={
                          <HealthKeySuspense>
                            <TermsOfUse />
                          </HealthKeySuspense>
                        }
                      />
                      <Route
                        path="/testing/activateEmail/:email"
                        element={
                          <HealthKeySuspense>
                            <TestingActivateEmail />
                          </HealthKeySuspense>
                        }
                      />
                    </Routes>
                  </MatomoHistoryListener>
                </ErrorBoundary>
              </HelmetProvider>
            </PersistGate>
          </Provider>
        </MotionConfig>
      </ThemeProvider>
    </>
  );
}
